/* eslint-disable */
import React, { Component } from 'react';
import { View, Image } from '@zhike/ti-ui';
import { Audio } from '@zhike/ti-component';
import Result from '../pigai/result';
import SuggestionList from '../pigai/suggestion_list';
import TextSegment from '../pigai/text_segment';
import Practice from '../pigai/practice';
import styles from './styles';
import upIcon from '../pigai/assets/up.png';
import downIcon from '../pigai/assets/down.png';
import loading from '../pigai/assets/loading-one.png'
import { get } from 'lodash'


export default class Suggestion extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isSelected: false,
      isFold: true,
      isLoading: false,
    };
  }

  handleClickSentence = (index) => {
    this.setState({ isSelected: this.state.isSelected === index ? null : index },
      () => {
        this.scrollToElement(`suggestion-${index}`)
    });
  };

  scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  };

  handleClickSuggestion = (type, index) => {
    this.setState({
      isSelected: `${type}_${index}`
    }, () => {
      // Scroll both elements into view
      this.scrollToElement(`suggestion-${type}-${index}`);
      this.scrollToElement(`sentence-${type}-${index}`);
    });
  };

  processSegments = () => {
    const { aiPigaiSTT, aiPigaiCompositonAnalysis } = this.props;

    if (!Array.isArray(aiPigaiCompositonAnalysis) || aiPigaiCompositonAnalysis.length === 0) {
      return [{ type: 'text', content: aiPigaiSTT }];
    }

    const segments = [];

    aiPigaiCompositonAnalysis.forEach((item, index) => {
      const normalTextStartIndex = index > 0 
        ? aiPigaiCompositonAnalysis[index - 1].endIndex + 1
        : 0;
        
      if (item.startIndex > normalTextStartIndex) {
        segments.push({
          type: 'text',
          content: aiPigaiSTT.substring(normalTextStartIndex, item.startIndex),
        });
      }

      segments.push({
        type: item.type,
        content: item.sentence,
        index: index,
      });
    });

    const lastItem = aiPigaiCompositonAnalysis[aiPigaiCompositonAnalysis.length - 1];

    if (lastItem.endIndex  < aiPigaiSTT.length) {
      segments.push({
        type: 'text',
        content: aiPigaiSTT.substring(lastItem.endIndex + 1),
      });
    }

    return segments;
  };

  // 计算文章单词个数
  calculateWordCount = () => {
    const { aiPigaiSTT } = this.props;
    if (!aiPigaiSTT) return 0;

    // Remove punctuation and extra spaces, then split by whitespace
    const words = aiPigaiSTT
      .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '')
      .replace(/\s+/g, ' ')
      .trim()
      .split(' ');

    // Filter out empty strings
    return words.filter(word => word.length > 0).length;
  };

  // 计算错误数量
  calculateErrorCount = () => {
    const { aiPigaiCompositonAnalysis } = this.props;
    if (!aiPigaiCompositonAnalysis) return 0;
    
    // 过滤出所有type为'bad'的错误项
    const errorCount = aiPigaiCompositonAnalysis.filter(item => item.type === 'bad').length;
    
    return errorCount;
  };

  // 计算错误类型
  calculateErrorTypeCount = () => {
    const { practice } = this.props;
    if (!practice.length) return 0;
    return practice.length
  }

  // 展开收起
  handleToggle = () => {
    if (!this.state.isFold) {
      this.setState({
        isFold: true,
        isLoading: true
      });
      
      setTimeout(() => {
        this.setState({ isLoading: false });
      }, 1000);
    } else {
      this.setState({
        isFold: false,
        isLoading: false
      });
    }
  }


  render() {
    const { answerAudio, aiPigaiCompositonAnalysis, practice, answerId, getCorrectData } = this.props;
    const segments = this.processSegments();
    // const wordCount = this.calculateWordCount();
    // const errorCount = this.calculateErrorCount();
    // const errorTypeCount = this.calculateErrorTypeCount();
    const statistics = get(this.props, 'statistics', {})

    const { isSelected } = this.state;

    return (
      <View>
        <Result statistics={statistics} isSpeaking={true} />
        <View className={styles.audioBox}>
          <h3>我的音频</h3>
          <View className={styles.audioBoxHeader}>
            <Audio src={answerAudio.src} />
            <View
              className={styles.foldIcon}
              onClick={() => this.handleToggle()}
            >
              <View className={styles.foldIcon}>
                <Image className={styles.icons} src={ this.state.isFold ? upIcon : downIcon } />
                <span style={{ marginRight: '5px' }}>{this.state.isFold ? '隐藏文本' : '显示文本'}</span>
              </View>
            </View>
          </View>
        </View>
        <View className={styles.pigaiContainer}>
          {this.state.isFold && 
            <View className={styles.audioText}>
              {this.state.isLoading ? (
                <Image className={styles.loading} src={loading} />
              ) : (
                <TextSegment
                  segments={segments}
                  isSelected={isSelected}
                  onClickSentence={this.handleClickSentence}
                />
              )}
            </View>
          }
          <View className={styles.suggestionContainer}>
            <SuggestionList
              isSelected={isSelected}
              list={aiPigaiCompositonAnalysis}
              onClickSentence={this.handleClickSuggestion}
            />
          </View>
        </View>
        <Practice list={aiPigaiCompositonAnalysis} practiceList={practice} answerId={answerId} getCorrectData={getCorrectData}  />
      </View>
    );
  }
}
