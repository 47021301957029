import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get, omit } from 'lodash';
import { View } from '@zhike/ti-ui';
import Suggestion from './suggestion';
import styles from './styles';

const speakingDimensionMap = {
  pronunciation: 'Pronunciation',
  grammaticalRangeAndAccuracy: 'Grammatical range and accuracy',
  lexicalResources: 'Lexical resources',
  fluencyCoherence: 'Fluency coherence',
}
// 报告
export default class SpeakingV2 extends Component {
  // 参数
  static propTypes = {
    correctData: PropTypes.object.isRequired,
  };

  // 构造函数
  constructor(props) {
    super(props);
    this.state = {
      tab: '',
    };
    this.audio = null;
  }

  handleScore(score, base) {
    const newScore = base ? parseInt(score * base, 10) : score;
    return newScore;
  }

  handleRotate(score) {
    let right = 225;
    let left = 225;
    if (score <= 15 && score > 0) {
      left = 225 - (180 * (score / 15));
    }
    if (score > 15) {
      left = 45;
      right = 225 - (180 * ((score - 15) / 15));
    }
    return {
      right,
      left,
    };
  }

  generateTab = () => {
    const { tab } = this.state;
    const { correctData } = this.props;
    const { aiPigaiResult } = correctData;
    const { pronunciation, grammaticalRangeAndAccuracy, lexicalResources, fluencyCoherence } = aiPigaiResult;
    const pronunciationScore = this.handleScore(pronunciation.score);
    const grammaticalRangeAndAccuracyScore = this.handleScore(grammaticalRangeAndAccuracy.score);
    const lexicalResourcesScore = this.handleScore(lexicalResources.score);
    const fluencyCoherenceScore = this.handleScore(fluencyCoherence.score);

    return (
      <View className={styles.tabs}>
        <View className={[styles.tab, tab === 'pronounce' && styles.tabSelect]}>
          <View className={styles.title}>Pronunciation</View>
          <View className={styles.circleProgressWrapper}>
            <View className={styles.circle1}>
              <View className={styles.circle2}>
                {pronunciationScore}分
              </View>
            </View>
            <View className={[styles.wrapper, styles.right]}>
              <View
                style={{ transform: `rotate(${this.handleRotate(pronunciationScore).right}deg)` }}
                className={[styles.circleProgress, styles.rightcircle]}
              />
            </View>
            <View className={[styles.wrapper, styles.left]}>
              <View
                style={{ transform: `rotate(${this.handleRotate(pronunciationScore).left}deg)` }}
                className={[styles.circleProgress, styles.leftcircle]}
              />
            </View>
          </View>
        </View>
        <View className={[styles.tab, tab === 'grammar' && styles.tabSelect]}>
          <View className={styles.title}>Grammatical Range and Accuracy</View>
          <View className={styles.circleProgressWrapper}>
            <View className={styles.circle1}>
              <View className={styles.circle2}>
                {grammaticalRangeAndAccuracyScore}分
              </View>
            </View>
            <View className={[styles.wrapper, styles.right]}>
              <View
                style={{ transform: `rotate(${this.handleRotate(grammaticalRangeAndAccuracyScore).right}deg)` }}
                className={[styles.circleProgress, styles.rightcircle]}
              />
            </View>
            <View className={[styles.wrapper, styles.left]}>
              <View
                style={{ transform: `rotate(${this.handleRotate(grammaticalRangeAndAccuracyScore).left}deg)` }}
                className={[styles.circleProgress, styles.leftcircle]}
              />
            </View>
          </View>
        </View>
        <View className={[styles.tab, tab === 'lexical' && styles.tabSelect]}>
          <View className={styles.title}>Lexical Resources</View>
          <View className={styles.circleProgressWrapper}>
            <View className={styles.circle1}>
              <View className={styles.circle2}>
                {lexicalResourcesScore}分
              </View>
            </View>
            <View className={[styles.wrapper, styles.right]}>
              <View
                style={{ transform: `rotate(${this.handleRotate(lexicalResourcesScore).right}deg)` }}
                className={[styles.circleProgress, styles.rightcircle]}
              />
            </View>
            <View className={[styles.wrapper, styles.left]}>
              <View
                style={{ transform: `rotate(${this.handleRotate(lexicalResourcesScore).left}deg)` }}
                className={[styles.circleProgress, styles.leftcircle]}
              />
            </View>
          </View>
        </View>
        <View className={[styles.tab, tab === 'fluency' && styles.tabSelect]}>
          <View className={styles.title}>Fluency & Coherence</View>
          <View className={styles.circleProgressWrapper}>
            <View className={styles.circle1}>
              <View className={styles.circle2}>
                {fluencyCoherenceScore}分
              </View>
            </View>
            <View className={[styles.wrapper, styles.right]}>
              <View
                style={{ transform: `rotate(${this.handleRotate(fluencyCoherenceScore).right}deg)` }}
                className={[styles.circleProgress, styles.rightcircle]}
              />
            </View>
            <View className={[styles.wrapper, styles.left]}>
              <View
                style={{ transform: `rotate(${this.handleRotate(fluencyCoherenceScore).left}deg)` }}
                className={[styles.circleProgress, styles.leftcircle]}
              />
            </View>
          </View>
        </View>
      </View>);
  }


  generateTabContent = () => {
    const aiPigaiResult = get(this, 'props.correctData.aiPigaiResult', {})
    const commonAIPigaiResult = omit(aiPigaiResult, ['all'])
    return (
      <View>
        {Object.entries(commonAIPigaiResult).map(([key, item]) => (
          <View key={key} className={styles.content}>
            <h4>{speakingDimensionMap[key]}</h4>
            <View>{`comment：${item.comment || 'None'}`}</View>
            {item.chinese_comment &&
              <View style={{ marginTop: '10px' }}>{`评语：${item.chinese_comment}`}</View>
            }
          </View>
        ))}
      </View>
    );
  }

  // 渲染
  render() {
    const { aiPigaiCompositonAnalysis, answerAudio, aiPigaiSTT } = this.props.correctData;
    const aiPigaiResult = get(this, 'props.correctData.aiPigaiResult', {})
    const commonAIPigaiResult = omit(aiPigaiResult, ['all'])
    const aiPigaiPractice = get(this, 'props.correctData.aiPigaiPractice', []);
    const answerId = get(this, 'props.correctData.answerId');
    const statistics = get(this, 'props.correctData.statistics', {});

    return (
      <View className={styles.container}>
        {(aiPigaiCompositonAnalysis && aiPigaiCompositonAnalysis.length > 0) &&
          <Suggestion
            aiPigaiCompositonAnalysis={aiPigaiCompositonAnalysis}
            answerAudio={answerAudio}
            aiPigaiSTT={aiPigaiSTT}
            practice={aiPigaiPractice}
            answerId={answerId}
            commonAIPigaiResult={commonAIPigaiResult}
            statistics={statistics}
          />
        }
        {this.generateTabContent()}
      </View>
    );
  }
}
